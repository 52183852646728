const standards = [
  {
    name: "Standard",
    tailwindClassNames: "bg-zinc-300 text-zinc-600",
  },
  {
    name: "Wysoka",
    tailwindClassNames: "bg-purple-300 text-purple-600",
  },
  {
    name: "Premium",
    tailwindClassNames: "bg-amber-300 text-amber-600",
  },
];

export default standards;
