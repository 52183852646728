import { SearchIcon } from "@heroicons/react/solid";
import Container from "common/components/Container";
import PianoCard from "common/components/PianoCard";
import SEO from "common/components/SEO";
import DefaultLayout from "common/layouts/Default";
import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo, useState } from "react";

import { useFiltersContext } from "./context/FiltersContext";
import filterProducts from "./filterProducts";
import Filters from "./Filters";
import NotFound from "./NotFound";
import Search from "./Search";

const Sklep: React.FC = () => {
  const data = useStaticQuery(graphql`
    query SKLEP_MODULE_QUERY {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(sklep)/" } }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        nodes {
          frontmatter {
            type
            title
            manufacture {
              built
              countryOfOrigin
              brandOrigin
            }
            condition
            standard
            look {
              color
              finish
            }
            price
            sku
            location
            images {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  `);

  const products = useMemo(
    () => data.allMarkdownRemark.nodes,
    [data.allMarkdownRemark.nodes]
  );

  const [showFilters, setShowFilters] = useState<boolean>(false);

  const { filters, filtersEmpty } = useFiltersContext();

  const filteredProducts = useMemo(() => {
    if (!filters.title && filtersEmpty) return products;

    return filterProducts(products, filters);
  }, [filters, filtersEmpty, products]);

  return (
    <DefaultLayout>
      <SEO
        title="Sklep internetowy"
        description="Używane i nowe fortepiany i pianina oraz akcesoria na sprzedaż w atrakcyjnych cenach."
      />

      <div className="relative">
        <Container>
          <Search showFilters={showFilters} setShowFilters={setShowFilters} />

          {showFilters && <Filters />}

          <div className={showFilters ? "mt-10" : "mt-6"}>
            {filteredProducts.length ? (
              <>
                <p className="mb-4 inline-flex items-center gap-2 text-zinc-400">
                  <SearchIcon className="h-5 w-5" />
                  <span>
                    Znaleziono{" "}
                    <span className="font-bold">{filteredProducts.length}</span>{" "}
                    {filteredProducts.length === 1 ? "wynik" : "wyniki"}
                  </span>
                </p>

                <div className="grid grid-cols-1 items-center gap-8 sm:grid-cols-2">
                  {filteredProducts.map(
                    ({ frontmatter }: { frontmatter: any; fields: any }) => {
                      return (
                        <PianoCard
                          key={frontmatter.title}
                          frontmatter={frontmatter}
                        />
                      );
                    }
                  )}
                </div>
              </>
            ) : (
              <NotFound />
            )}
          </div>
        </Container>

        {/* Blob */}
        <svg
          width="100%"
          viewBox="0 0 200 200"
          xmlns="http://www.w3.org/2000/svg"
          className="absolute top-0 z-[-10] scale-150 sm:scale-125 md:-top-40 md:scale-100"
        >
          <path
            className="z-[-10] fill-zinc-800 opacity-[14%]"
            d="M48.4,-55.7C64,-44.5,78.9,-30.5,78.1,-16.6C77.2,-2.6,60.5,11.4,48.8,24.9C37.1,38.5,30.3,51.6,19.5,57.1C8.6,62.6,-6.3,60.4,-23.2,57.1C-40.1,53.8,-58.9,49.4,-64,38.1C-69.1,26.9,-60.4,8.9,-57.5,-10.5C-54.5,-29.9,-57.2,-50.7,-48.6,-63.1C-40,-75.6,-20,-79.7,-1.8,-77.5C16.4,-75.4,32.7,-67,48.4,-55.7Z"
            transform="translate(100 100)"
          />
        </svg>
      </div>
    </DefaultLayout>
  );
};

export default Sklep;
