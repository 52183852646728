import Button from "common/components/Button";
import React from "react";

import { useFiltersContext } from "./context/FiltersContext";

const NotFound: React.FC = () => {
  const { resetFilters, updateFilters } = useFiltersContext();

  const handleResetFilters = () => {
    resetFilters();
    updateFilters({ title: "" });
  };

  return (
    <div className="mx-auto mt-10 max-w-xl md:mt-16">
      <p className="mt-2 text-xl font-semibold sm:text-2xl md:text-3xl">
        Nie znaleziono produktu, którego szukasz 😬
      </p>

      <p className="mt-4 text-zinc-300 sm:text-lg">
        Spróbuj wybrać inne filtry/zmodyfikować wszukiwaną frazę
      </p>

      <Button
        customClasses="mt-8 text-sm md:text-lg"
        onClick={handleResetFilters}
        outlined
      >
        Wyczyść wszystkie filtry
      </Button>
    </div>
  );
};

export default NotFound;
