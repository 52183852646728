import { BackspaceIcon } from "@heroicons/react/solid";
import Button from "common/components/Button";
import React from "react";

import { useFiltersContext } from "../context/FiltersContext";
import Color from "./Color";
import Location from "./Location";
import Standard from "./Standard";

const Filters: React.FC = () => {
  const { filtersEmpty, resetFilters } = useFiltersContext();

  return (
    <div className="mt-8">
      <div className="grid w-full grid-cols-1 gap-8 md:grid-cols-3 md:gap-12">
        <Standard />
        <Color />
        <Location />
      </div>

      {!filtersEmpty && (
        <div className="mt-8 text-right">
          <Button
            customClasses="inline-flex items-center gap-2 text-red-300 text-base ring-red-300 justify-center"
            onClick={resetFilters}
            outlined
          >
            Wyczyść filtry
            <BackspaceIcon className="h5 w-5" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Filters;
