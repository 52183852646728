import Sklep from "modules/Sklep";
import FiltersContextProvider from "modules/Sklep/context/FiltersContext";
import React from "react";

const InstrumentyNaSprzedazPage: React.FC = () => {
  return (
    <FiltersContextProvider>
      <Sklep />
    </FiltersContextProvider>
  );
};

export default InstrumentyNaSprzedazPage;
