import { SparklesIcon, TagIcon } from "@heroicons/react/outline";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import assignTextColor from "./assignTextColor";

interface PianoCardProps {
  frontmatter: any;
}

const PianoCard: React.FC<PianoCardProps> = ({ frontmatter }) => {
  const { type, title, images, sku, standard, price } = frontmatter;

  return (
    <GatsbyLink to={sku} className="h-full">
      <div
        className="grid h-full grid-cols-1 items-center gap-4 rounded-xl bg-gradient-to-br from-zinc-900 to-black sm:gap-6 sm:p-0 md:grid-cols-2"
        data-test="product-card"
      >
        <GatsbyImage
          image={images[0].childImageSharp.gatsbyImageData}
          alt={title}
          className="h-[100px] w-full rounded-xl sm:h-[160px] md:h-[260px]"
          imgClassName="rounded-xl"
        />

        <div className="w-full px-4 pb-4 sm:p-0 sm:py-6 sm:pr-4 ">
          <h3 className="flex flex-col text-2xl">
            <span className="text-lg text-zinc-300">{type}</span>
            <span className="font-semibold">{title}</span>
          </h3>

          <p
            className={`mt-1 flex items-center gap-1 text-lg ${assignTextColor(
              standard
            )}`}
          >
            <SparklesIcon className="h-4 w-4 text-xl text-inherit" />
            <span>Klasa {standard}</span>
          </p>

          <p className="mt-4 flex items-center gap-1 text-lg">
            <TagIcon className="h-4 w-4 text-amber-300" />
            {price} PLN
          </p>
        </div>
      </div>
    </GatsbyLink>
  );
};

export default PianoCard;
