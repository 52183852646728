import React, { useContext, useMemo, useState } from "react";

export interface Filters {
  title: string;
  colors: Array<string>;
  finishes: Array<string>;
  locations: Array<string>;
  standards: Array<string>;
}

interface FiltersContextType {
  filters: Filters;
  updateFilters: (filters: Partial<Filters>) => void;
  resetFilters: () => void;
  filtersEmpty: boolean;
  count: number;
}

const initialFilters: Filters = {
  title: "",
  colors: [],
  finishes: [],
  locations: [],
  standards: [],
};

export const FiltersContext = React.createContext<FiltersContextType | null>(
  null
);

const FiltersContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [filters, setFilters] =
    useState<FiltersContextType["filters"]>(initialFilters);

  function updateFilters(
    newFilters: Partial<FiltersContextType["updateFilters"]>
  ) {
    setFilters((prevFilters: Filters) => ({ ...prevFilters, ...newFilters }));
  }

  function resetFilters() {
    setFilters((prevFilters: Filters) => ({
      ...initialFilters,
      title: prevFilters.title,
    }));
  }

  const { colors, finishes, locations, standards } = filters;

  const filtersEmpty =
    !colors.length &&
    !finishes.length &&
    !locations.length &&
    !standards.length;

  const count = useMemo(
    () => colors.length + finishes.length + locations.length + standards.length,
    [colors, finishes, locations, standards]
  );

  return (
    <FiltersContext.Provider
      value={{ filters, updateFilters, resetFilters, filtersEmpty, count }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersContextProvider;

export const useFiltersContext = () =>
  useContext(FiltersContext) as FiltersContextType;
