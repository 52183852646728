import type { Filters } from "./context/FiltersContext";

export default function filterProducts(products: any, filters: Filters) {
  const filteredProducts = products.filter((product: any) => {
    const { title, type, location, standard } = product.frontmatter;
    const { color } = product.frontmatter.look;

    let searchFriendlyTitle = title;
    if (type !== "Inne") searchFriendlyTitle = [type, title].join(" ");

    function matchingStandard() {
      if (!filters.standards.length) return true;

      return filters.standards.includes(standard);
    }

    function matchingColor() {
      if (!filters.colors.length) return true;

      return filters.colors.includes(color);
    }

    function matchingLocation() {
      if (!filters.locations.length) return true;

      return filters.locations.includes(location);
    }

    return (
      searchFriendlyTitle.toLowerCase().includes(filters.title.toLowerCase()) &&
      matchingStandard() &&
      matchingColor() &&
      matchingLocation()
    );
  });

  return filteredProducts;
}
