import { CheckIcon, SparklesIcon } from "@heroicons/react/solid";
import { useFiltersContext } from "modules/Sklep/context/FiltersContext";
import React from "react";

import Disclosure from "../Disclosure";
import standardsArray from "./standards";

const Standard: React.FC = () => {
  const { filters, updateFilters } = useFiltersContext();

  const handleClick = (standard: string) => {
    if (filters.standards.includes(standard)) {
      updateFilters({
        standards: filters.standards.filter(
          (presentStandard) => presentStandard !== standard
        ),
      });
      return;
    }

    updateFilters({ standards: [...filters.standards, standard] });
  };

  return (
    <Disclosure title="Klasa">
      <div role="group" aria-label="Klasa" className="flex items-center gap-3">
        {standardsArray.map((standard) => {
          const checked = filters.standards.includes(standard.name);

          return (
            <button
              key={standard.name}
              onClick={() => handleClick(standard.name)}
              className="flex flex-col items-center gap-1"
              aria-checked={checked}
              role="checkbox"
            >
              <div
                className={`relative h-10 w-10 rounded-full p-3 ${
                  standard.tailwindClassNames
                } ${checked ? "bg-opacity-40" : ""}`}
              >
                <SparklesIcon
                  className={`h-ful w-full ${checked ? "opacity-40" : ""}`}
                />

                {checked && (
                  <CheckIcon className="absolute inset-0 z-10 m-auto h-5 w-5 text-green-300" />
                )}
              </div>

              <p>{standard.name}</p>
            </button>
          );
        })}
      </div>
    </Disclosure>
  );
};

export default Standard;
