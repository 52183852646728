import { CheckIcon } from "@heroicons/react/solid";
import { useFiltersContext } from "modules/Sklep/context/FiltersContext";
import React from "react";

import Disclosure from "../Disclosure";
import colors from "./colors";

const Color: React.FC = () => {
  const { filters, updateFilters } = useFiltersContext();

  const handleClick = (color: string) => {
    if (filters.colors.includes(color)) {
      updateFilters({
        colors: filters.colors.filter((presentColor) => presentColor !== color),
      });
      return;
    }

    updateFilters({ colors: [...filters.colors, color] });
  };

  return (
    <Disclosure title="Kolor">
      <div
        role="group"
        aria-label="Kolor"
        className="flex flex-wrap items-center gap-3"
      >
        {colors.map((color) => {
          const checked = filters.colors.includes(color.name);

          return (
            <button
              key={color.name}
              onClick={() => handleClick(color.name)}
              className="flex flex-col items-center gap-1"
              aria-checked={checked}
              role="checkbox"
              aria-label={color.name}
            >
              <div
                className={`relative h-10 w-10 rounded-full p-3 ring-1 ring-zinc-800/60 ${
                  color.tailwindClassNames
                } ${checked ? "bg-opacity-40" : ""}`}
              >
                {checked && (
                  <CheckIcon className="absolute inset-0 z-10 m-auto h-5 w-5 text-green-300" />
                )}
              </div>
            </button>
          );
        })}
      </div>
    </Disclosure>
  );
};

export default Color;
