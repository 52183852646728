import { Disclosure as HUIDisclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React from "react";

interface DisclosureProps {
  title: string;
  children: React.ReactNode;
}

const Disclosure: React.FC<DisclosureProps> = ({ title, children }) => {
  return (
    <HUIDisclosure defaultOpen as="div">
      {({ open }) => (
        <>
          <HUIDisclosure.Button
            className={`flex w-full items-center justify-between rounded-lg text-left text-lg font-medium`}
          >
            <p>{title}</p>
            <ChevronDownIcon
              className={`h-5 w-5 ${open ? "rotate-180 transform" : ""}`}
            />
          </HUIDisclosure.Button>

          <HUIDisclosure.Panel className="mt-2 flex gap-4">
            {children}
          </HUIDisclosure.Panel>
        </>
      )}
    </HUIDisclosure>
  );
};

export default Disclosure;
