import { CheckIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import InfoChip from "common/components/InfoChip";
import { useFiltersContext } from "modules/Sklep/context/FiltersContext";
import React from "react";

import Disclosure from "../Disclosure";
import locationsArray from "./locations";

const Location: React.FC = () => {
  const { filters, updateFilters } = useFiltersContext();

  const handleClick = (location: string) => {
    if (filters.locations.includes(location)) {
      updateFilters({
        locations: filters.locations.filter(
          (presentLocation) => presentLocation !== location
        ),
      });
      return;
    }

    updateFilters({ locations: [...filters.locations, location] });
  };

  return (
    <Disclosure title="Lokalizacja">
      <div
        role="group"
        aria-label="Lokalizacja"
        className="mt-2 flex flex-col items-start gap-4"
      >
        {locationsArray.map((location) => {
          const checked = filters.locations.includes(location);

          const [street, city] = location.split(",");

          return (
            <button
              key={location}
              onClick={() => handleClick(location)}
              aria-checked={checked}
              role="checkbox"
            >
              <InfoChip
                customClasses="font-medium"
                icon={
                  checked ? (
                    <CheckIcon className="h-5 w-5" />
                  ) : (
                    <LocationMarkerIcon className="h-5 w-5" />
                  )
                }
                color={checked ? "green" : "zinc"}
                title={city.trim()}
                content={street}
              />
            </button>
          );
        })}
      </div>
    </Disclosure>
  );
};

export default Location;
