import { FilterIcon } from "@heroicons/react/outline";
import Button from "common/components/Button";
import Input from "common/components/Input";
import React, { Dispatch, SetStateAction } from "react";

import { useFiltersContext } from "../context/FiltersContext";

interface SearchProps {
  showFilters: boolean;
  setShowFilters: Dispatch<SetStateAction<boolean>>;
}

const Search: React.FC<SearchProps> = ({ showFilters, setShowFilters }) => {
  const { filters, updateFilters, count } = useFiltersContext();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilters({ title: e.target.value });
  };

  const handleFiltersToggle = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div className="mt-4 flex items-center justify-center gap-4">
      <Input
        name="search"
        type="search"
        placeholder="np. Pianino, fortepian, yamaha"
        customContainerClasses="w-full md:max-w-lg"
        value={filters.title}
        onChange={onInputChange}
        aria-label="Search products"
      />

      <Button
        aria-label="Filtry"
        onClick={handleFiltersToggle}
        customClasses="relative block"
        icon
      >
        <FilterIcon className="h-5 w-5 sm:h-6 sm:w-6" />

        {count ? (
          <div className="absolute top-0 right-0 h-5 w-5 rounded-full bg-green-900 text-sm font-bold tracking-tighter text-green-300 ring ring-black">
            {count}
          </div>
        ) : (
          ""
        )}
      </Button>
    </div>
  );
};

export default Search;
